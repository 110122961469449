:root {
  --cc-btn-primary-bg: #AE1A0A;
  --cc-btn-secondary-bg: #d8d8d8;
  --cc-toggle-readonly-bg: #d5dee2;
}

#cc-main {
  font-family: "DM Sans", sans-serif;
  #cm__desc {
    text-align: center;
  }
  .section__toggle:checked:disabled ~ .toggle__icon {
    background-color: #AE1A0A;
    svg {
      stroke: #AE1A0A;
    }
  }
}
