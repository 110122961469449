.libro {
  padding-left: 1rem;
  padding-right: 1rem;
}
.libro-hover {
  position: relative;
  margin-bottom: 0.5rem;
  display: inline-block;
  max-width: 160px;
  width: 100%;
  &:hover {
    .libro-hover__overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  .libro__ranking {
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    width: 40px;
    height: 40px;
    top: -20px;
    background-color: $white;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  .libro__portada {
    width: 100%;
  }
  .libro-hover__overlay {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    min-height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 15px 15px 8px 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
    line-height: 1.2;
    transition: all 0.2s;
    .libro__info {
      .libro__titulo {
        @extend .stretched-link;
        font-weight: 700;
      }
      .libro__autor,
      .libro__titulo,
      .libro__precio {
        display: block;
        color: $white;
      }
    }
  }
}

.libro__compra {
  margin-left: auto;
  display: flex;
  z-index: 2;
  > .btn {
    border-radius: 50%;
    text-align: center;
    padding: 0;
    float: right;
    @include font-size(18px);
    margin-left: 8px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: $white;
    color: $black;
  }
}

.libro__precio {
  @include font-size(20px);
  font-weight: 700;
  margin-top: 0.5rem;
}
