// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "DM Sans", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: rgb(0, 0, 0) !default;

/// Main brand color
/// @type Color
$brand-color: #e92230 !default;

/// Light grey
/// @type Color
$light-grey: #eaeaea !default;

/// Medium grey
/// @type Color
$mid-grey: #ddd !default;

/// Dark grey
/// @type Color
$dark-grey: #747474 !default;

$sticker-height: 30px;

// Bootstrap responsive font size
$enable-responsive-font-sizes: true !default;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: #626262;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10.37' width='1em' height='0.75em'><path fill='#{$breadcrumb-divider-color}' d='m1.07,9.87c-.12,0-.25-.05-.34-.14-.2-.19-.21-.51-.02-.71l3.61-3.82L.64,1.34c-.19-.2-.18-.52.02-.71.2-.19.52-.18.71.02l4,4.2c.18.19.18.49,0,.69l-3.93,4.17c-.1.1-.23.16-.36.16Z'/><path fill='#{$breadcrumb-divider-color}' d='m1.07,10.37c-.25,0-.49-.09-.69-.27-.4-.38-.42-1.01-.04-1.41l3.28-3.48L.28,1.69C-.1,1.29-.09.66.31.28c.4-.38,1.03-.36,1.41.03l4,4.2c.37.39.37.99,0,1.38l-3.93,4.17c-.2.21-.46.31-.73.31Z'/></svg>");
$breadcrumb-padding-x: 0;
$breadcrumb-active-color: $text-color;
$breadcrumb-item-padding: 1rem;
