#home {
  margin-top: 1rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 1rem;
  }
}

#home__destacados {
  .slick-slide {
    > div {
      display: flex;
      height: 100%;
    }
    img {
      width: 100%;
    }
  }
  .slick-arrow {
    background-color: rgba(0, 0, 0, 0.5) !important;
    &::before {
      color: #fff;
    }
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      right: 20px;
    }
  }
  .destacado {
    min-height: 300px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    @include media-breakpoint-down(sm) {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .destacado__imagen {
    display: none;
    align-items: center;
    width: 100%;
    picture,
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      min-height: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @include media-breakpoint-down(md) {
    img {
      height: 350px;
      object-fit: cover;
    }
  }
  .destacado__caption {
    color: $white;
    padding: 30px 20px;
    min-height: 0;
    .btn {
      display: none;
    }
    @include media-breakpoint-up(md) {
      background-color: rgba(0, 0, 0, 0.5);
      // height: 100%;
      // min-height: 450px;
      .btn {
        display: inline-block;
      }
    }
  }
  .destacado__titulo {
    @include font-size(34px);
    font-weight: 700;
    line-height: 1;
    font-family: "Crimson Pro", serif;
  }
  .destacado__descripcion {
    @include font-size(20px);
    font-weight: 400;
    line-height: 1.2;
  }
  .destacado__portada {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    max-width: 100%;
    height: auto;
    width: 85px;
    @include media-breakpoint-up(md) {
      width: 140px;
    }
  }
}

#home__novedades {
  padding-top: 1.5rem;
  padding-bottom: 0;
  .slider {
    margin-top: 1rem;
  }
}

#home__agenda {
  .carousel-section {
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }
  .titulo {
    margin-bottom: 1rem;
  }
  .btn-agenda {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .btn {
      width: 100%;
    }
  }
  .evento {
    background-color: transparent;
  }
}

#home__redes {
  margin-bottom: 3rem;
  a {
    text-decoration: none;
  }
  .redes__header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    white-space: normal;
    background-color: $light-grey;
    border-bottom: 1px solid $dark-grey;
    font-weight: 400;
    line-height: 1.2;
    i {
      color: $dark-grey;
    }
  }
  .redes__logo {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
    width: 80px;
    @include media-breakpoint-down(md) {
      width: 60px;
    }
  }
  .instafeed {
    overflow: hidden;
    overflow-y: scroll;
  }
  .instafeed__img {
    width: 100%;
  }
  .instafeed__user {
    font-weight: 700;
    margin-right: 0.5rem;
  }
  .instafeed__caption {
    margin-bottom: 1rem;
    padding: 1.5rem;
    line-height: 1.2;
  }
  .instafeed,
  .twitter-timeline {
    background-color: $light-grey;
    height: 630px;
    @include media-breakpoint-down(md) {
      height: 400px !important;
    }
  }
  .btn {
    width: 100%;
  }
}

#home__blog {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .titulo {
    text-align: center;
    @include media-breakpoint-up(sm) {
      margin-bottom: 3rem;
    }
    @include media-breakpoint-only(xs) {
      margin-bottom: 1rem;
    }
  }
}

.firmas-nav {
  cursor: pointer;
}
