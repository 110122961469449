#pagination {
  margin-top: 3rem;
  margin-bottom: 3rem;
  @include media-breakpoint-only(xs) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.pagination {
  .page-item {
    margin: 1px;
    .page-link {
      border: 0;
      color: $text-color;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($brand-color, 0.25);
      }
    }
    &.active {
      .page-link {
        background-color: $light-grey;
        color: $text-color;
      }
    }
  }
}
