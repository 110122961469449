#boletines {
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid $dark-grey;
  border-left: 1px solid $dark-grey;
  font-size: 14px;
  .boletines__caja {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-right: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    .custom-checkbox {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
