// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Basic styles for body
 */
body {
  background-color: $white;
}

/**
 * Basic styles for links
 */
a {
  color: $text-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

.bg-color--light-grey {
  background-color: $light-grey;
}

#page-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: $zindex-modal-backdrop;
}

.enlaces-redes {
  display: flex;
  align-items: center;
  a {
    display: inline-flex;
    align-items: center;
    margin-right: 0.5rem;
    text-decoration: none;
    i {
      font-size: 22px;
      color: $dark-grey;
    }
  }
}
