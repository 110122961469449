#autores {
  .title {
    font-family: "Crimson Pro", serif;
    font-weight: 700;
  }

  .search-icon {
    background-color: #cb010f !important;
    color: white !important;
  }

  .col-list {
    .item {
      @extend .my-3, .p-0;
      display: flex;
      flex-direction: column;
      border-left: 15px solid $white;
      border-right: 15px solid $white;
      background-color: $light-grey;
      height: 25rem;
      .image {
        height: 50%;
        position: relative;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .info {
        flex: 1;
        padding: 1rem;
        .title {
          @extend .mb-0;
          font-size: 22px;
          font-weight: 400;
          font-style: normal;
        }
        .description {
          @extend .mt-3;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
      a {
        @extend .stretched-link;
      }
    }
  }

  #alphabet,
  #pagination {
    .page-link {
      color: #000;
      border: none;
      &:hover {
        background-color: white;
      }
    }
  }
}

#autor {
  .title {
    font-family: "Crimson Pro", serif;
    font-weight: 700;
  }

  .image {
    @extend .rounded-circle, .mx-auto, .mb-4;
    width: 285px;
    height: 285px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b2b2b2;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image-list {
    @extend .w-100, .position-relative;
    padding-top: 70%;
    overflow: hidden;
    background-color: #b2b2b2;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title-list {
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    font-size: 1rem;
  }

  #sinopsis__text {
    -webkit-line-clamp: 8 !important;
    line-clamp: 8 !important;
  }

  #more-toggle {
    display: none;
    width: 100%;
    border-color: $black;
  }
}
