#direcciones {
  .custom-radio {
    margin-bottom: 1.5rem;
    .custom-control-label {
      display: flex;
      .btn--edit {
        margin: 0;
        margin-left: 1rem;
        align-self: flex-start;
        color: $dark-grey;
      }
    }
  }
  .direccion {
    margin-bottom: 1.5rem;
    .direccion__predeterminada,
    .direccion__eliminar {
      @extend .fs-14;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    > .btn--edit,
    > .btn--del {
      margin-right: 0.5rem;
      color: $dark-grey;
    }
  }
  .indicaciones {
    @extend .predeterminada__actual;
  }

  .no-gutters {
    .btn {
      width: 100%;
      height: 100%;
    }
  }
  #direccionesform {
    margin-top: 1rem;
    .form-control,
    .custom-select {
      margin-bottom: 1.5rem;
    }
  }
}
