// -----------------------------------------------------------------------------
// This file contains all styles related to the badge component.
// -----------------------------------------------------------------------------

.badge {
  @extend .fs-14;
  padding: 0.5rem;
  text-transform: uppercase;
  &:not(.badge-pill) {
    border-radius: 0;
  }
  letter-spacing: 0.66px;
  &.badge--primary {
    background-color: $dark-grey;
    color: $white;
  }
}
