// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.pre-header {
  background-color: #d8d8d8;
}

.pre-header + .navbar {
  top: $sticker-height;
}

.pre-header.d-none.d-lg-block + .navbar {
  @include media-breakpoint-down(md) {
    top: 0;
  }
}

.pre-header + .navbar--scroll {
  top: 0;
}

#header-cesta {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $light-grey;
  #header__titulo {
    @include font-size(32px);
    font-weight: 700;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: auto;
    line-height: 1.1;
  }
  #header__logo {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    #logo__img {
      max-width: 100%;
      height: auto;
      width: 100px;
    }
  }
  #header__back {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    #back__link {
      @extend .btn, .btn--secondary;
    }
  }
}

#header {
  background-color: $white;
  width: 100vw;

  &__btn-newsletter {
    height: $sticker-height;
    @include media-breakpoint-down(md) {
      font-size: 0.785rem;
    }
  }

  .header__usuario {
    @include font-size(14px);
    .dropdown {
      .dropdown-toggle {
        padding: 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        i {
          margin-left: 0.5rem;
          font-size: 22px;
        }
        #usuario__nombre {
          @include media-breakpoint-down(md) {
            display: none;
          }
          vertical-align: middle;
        }
      }
      .dropdown-menu {
        padding: 0;
        margin-top: 1rem;
        border: 1px solid $dark-grey;
        &::before {
          content: "";
          position: absolute;
          top: -14px;
          @include media-breakpoint-up(lg) {
            left: calc(50% - 10px);
          }
          @include media-breakpoint-down(md) {
            right: 20px;
          }
          border-right: 10px solid transparent;
          border-bottom: 14px solid $dark-grey;
          border-left: 10px solid transparent;
          border-bottom-color: $dark-grey;
        }
        @include media-breakpoint-down(md) {
          margin-right: -20px;
        }
        .dropdown-item {
          padding: 0.5rem 1rem;
          @include font-size(14px);
          @include on-event() {
            background-color: transparent;
          }
          font-weight: 400;
          color: $black;
        }
      }
    }
  }
  .header__cesta {
    .dropdown {
      .dropdown-toggle {
        padding: 0;
        position: relative;
        i {
          font-size: 22px;
        }
        .cesta__badge {
          @include font-size(14px);
          position: absolute;
          bottom: 0;
          right: 0;
          color: $white;
          font-weight: 400;
          line-height: 18px;
          width: 18px;
          height: 18px;
          border-radius: 100%;
          text-align: center;
        }
      }
      .dropdown-menu {
        color: $black;
        .cesta__header {
          border-bottom: 1px solid $dark-grey;
          .cesta__contador {
            margin-right: auto;
          }
        }
        .btn {
          width: 100%;
        }
      }
    }
  }

  #header__navbar {
    @include media-breakpoint-up(lg) {
      > .row {
        border-bottom: 1px solid#E6E5E5;
        padding-bottom: 1rem;
      }
    }
    .navbar-toggler {
      font-size: 32px;
      color: #b3b2b2;
    }
    #navbar__collapse {
      .navbar-nav {
        position: relative;
        width: 100%;
        .nav-item {
          position: relative;
          @include media-breakpoint-up(lg) {
            &.active {
              .nav-link {
                border-bottom: 5px solid $dark-grey;
              }
            }
            &:not(.active) {
              .nav-link {
                @include on-event() {
                  text-decoration: underline;
                }
              }
            }
          }
          @include media-breakpoint-down(md) {
            flex: none;
            width: 100%;
            .nav-link {
              border-top: 1px solid $dark-grey;
            }
            &:last-child {
              border-bottom: 1px solid $dark-grey;
            }
            &.active {
              .nav-link {
                font-weight: bold;
              }
            }
          }
          .nav-link {
            padding-left: 0;
            padding-right: 0;
            text-transform: uppercase;
            text-align: center;
            &.dropdown-toggle {
              &::after {
                display: inline-block;
                margin-left: 0.75em;
                vertical-align: 0;
                content: "";
                border-top: 0.7em solid;
                border-right: 0.5em solid transparent;
                border-bottom: 0;
                border-left: 0.5em solid transparent;
                color: #d8d8d8;
              }
            }
          }
          &.dropdown {
            .dropdown-menu {
              @include media-breakpoint-up(lg) {
                border: 1px solid $dark-grey;
                background-color: $white;
              }
              @include media-breakpoint-down(md) {
                position: static !important;
                transform: none !important;
                border-top: 1px solid $dark-grey;
                background-color: $light-grey;
              }
              .dropdown-item {
                text-align: center;
                text-transform: uppercase;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                color: $black;
                @include on-event() {
                  background-color: transparent;
                }
              }
            }
          }
          &.dropdown-catalogo {
            .dropdown-menu {
              .dropdown-item {
                white-space: normal;
                padding-right: 15px;
                padding-left: 15px;
                line-height: 1.2;
              }
            }
            &.show {
              position: static;
              .dropdown-menu {
                display: flex;
                flex-wrap: wrap;
                .dropdown-item {
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  @include media-breakpoint-only(xl) {
                    width: 20%;
                  }
                  @include media-breakpoint-only(lg) {
                    width: 25%;
                  }
                }
              }
            }
          }
          @include media-breakpoint-up(lg) {
            &:not(.dropdown-catalogo) {
              .dropdown-menu {
                left: -50% !important;
              }
            }
          }
        }
      }
    }
  }
}

.header__buscador {
  .input-group-prepend {
    .btn {
      @include media-breakpoint-only(xs) {
        font-size: 13px;
      }
    }
  }
  .input-group-text {
    border-color: #979797;
    color: #555555;
    background-color: transparent;
  }
  .form-control {
    background-color: transparent;
    border: 1px solid #979797 !important;
    height: auto;
    border-right: 0 !important;
  }
}

.header-claim {
  @include media-breakpoint-only(xs) {
    font-size: 11px;
  }
  text-align: center;
  &__title {
    font-weight: 700;
  }
  &__subtitle {
    font-family: "Crimson Pro", serif;
  }
}

.header-search-toggler {
  display: none;
  .fa-times {
    display: none;
  }
  .fa {
    width: 16px;
  }
  &--active {
    .fa-search {
      display: none;
    }
    .fa-times {
      display: block;
    }
  }
}

.navbar-brand {
  @include media-breakpoint-up(lg) {
    width: 100px;
  }
  img {
    @include media-breakpoint-only(xs) {
      width: 45px;
      height: 45px;
    }
    @include media-breakpoint-up(sm) {
      width: 60px;
      height: 60px;
    }
    @include media-breakpoint-up(lg) {
      width: 100px;
      height: 100px;
    }
  }
}

.navbar--scroll {
  .header-search-wrapper {
    display: none;
    flex-basis: 100%;
    order: 8;
  }
  @include media-breakpoint-up(lg) {
    .navbar-brand {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    #header__btn-newsletter {
      display: none !important;
    }
  }
  @include media-breakpoint-up(lg) {
    #header__navbar {
      > .row {
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    .navbar-brand {
      margin-top: 0;
    }
    .header-search-toggler {
      display: block;
    }
    .header-search-toggler,
    .header__cesta {
      order: 7;
    }
    .navbar-collapse {
      flex-basis: 0;
    }
  }
}
