#agenda__eventos {
  margin-bottom: 30px;
  .evento-wrapper {
    margin-top: 30px;
  }
  .evento {
    height: 410px;
  }
}

#agenda__filtros {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: $light-grey;
  #agenda__calendario-toggler {
    font-size: 20px;
  }
}

#agenda__calendario {
  .collapsing {
    display: none !important;
    transition: none !important;
  }
  border-bottom: 1px solid $black;
  .btn-mes {
    @include font-size(14px);
    display: block;
    margin: 1rem 0.5rem;
    font-weight: 400;
    background-color: $light-grey;
  }
  .btn-mes--active {
    background-color: $dark-grey;
    color: $white;
  }
  .slick-track {
    align-items: center;
  }
  .slick-arrow {
    background-color: transparent !important;
    width: auto !important;
    height: auto !important;
    &::before {
      color: $black;
      font-size: 16px !important;
    }
    &.slick-prev {
      left: -10px;
    }
    &.slick-next {
      right: -10px;
    }
  }
}
