#blog-header {
  margin-top: 3rem;
  border-bottom: 1px solid $black;
  .titulo {
    text-align: center;
  }
}

.blog-articulo {
  line-height: 1.2;
  .libro-hover {
    z-index: 1;
  }
  a {
    @extend .stretched-link;
    text-decoration: underline;
    @include on-event() {
      text-decoration: none;
    }
  }
}
.blog-articulo--destacado {
  padding: 1.5rem;
  .blog-articulo__portada {
    margin-bottom: 0;
    @include media-breakpoint-only(xs) {
      margin-bottom: 1rem;
    }
    margin-bottom: 1rem;
  }
}
.blog-articulo__imagen {
  width: 160px;
  margin-bottom: 1rem;
}
.blog-articulo__tipo {
  @include font-size(14px);
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 400;
}
.blog-articulo__titulo,
.blog-articulo__subtitulo {
  font-family: "Crimson Pro", serif;
  @include font-size(32px);
  line-height: 1;
}
.blog-articulo__titulo {
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.blog-articulo__subtitulo {
  font-style: italic;
  margin-bottom: 1rem;
  font-weight: 400;
}
.blog-articulo__autor {
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.blog-articulo__portada-wrapper {
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  max-width: 230px;
  &.big {
    max-width: 350px;
  }
}
.blog-articulo__portada {
  width: 100%;
  margin-bottom: 1rem;
  @include when-inside(".blog-articulo__portada-wrapper") {
    width: 230px;
    &.big {
      width: 350px;
    }
    max-width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blog-articulo__contenido {
  margin-top: 3rem;
  .img-wrapped {
    float: left;
    margin-right: 1rem;
    .libro {
      padding: 0;
    }
  }
}

#blog-collage {
  .blog-articulo {
    padding: 1.5rem;
    &:after,
    &:before {
      background: $black;
      content: " ";
      position: absolute;
    }
    @include media-breakpoint-up(sm) {
      &::before {
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
      }
    }
    @include media-breakpoint-down(md) {
      &:first-child {
        &::before {
          content: none;
        }
      }
    }
    @include media-breakpoint-only(xs) {
      &::after {
        left: 50%;
        bottom: 0;
        width: calc(100% - 45px);
        height: 1px;
        transform: translateX(-50%);
      }
    }
    .libro-hover {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 180px;
    }
  }
  > [class^="col-"],
  > [class*=" col-"] {
    &:first-child {
      > .row {
        .blog-articulo {
          .blog-articulo__portada-wrapper {
            @include media-breakpoint-up(lg) {
              margin-left: auto;
            }
          }
          @include media-breakpoint-up(lg) {
            &::before {
              content: none;
            }
            &:first-child {
              &::after {
                left: 0;
                bottom: 0;
                width: calc(100% - 25px);
                height: 1px;
              }
            }
          }
          @include media-breakpoint-down(md) {
            &::after {
              left: 50%;
              bottom: 0;
              width: calc(100% - 45px);
              height: 1px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    &:nth-child(2) {
      @include media-breakpoint-down(md) {
        &::before {
          content: none;
        }
      }
    }
    &:last-child {
      @include media-breakpoint-only(xs) {
        &::after {
          content: none;
        }
      }
    }
  }
}

#blog-vertical {
  .blog-articulo {
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    &:after,
    &:before {
      background: $black;
      content: " ";
      position: absolute;
    }
    &:after {
      right: 0;
      width: 1px;
      top: 0;
      height: 100%;
      display: none;
    }
    &:before {
      left: 0;
      width: 100%;
      top: -25px;
      height: 1px;
      @include media-breakpoint-only(xs) {
        left: 50%;
        width: calc(100% - 30px);
        transform: translateX(-50%);
      }
    }
    @include media-breakpoint-between(lg, xl) {
      &:not(:last-child):not(:nth-child(4n)) {
        &:after {
          display: block;
        }
      }
    }
    @include media-breakpoint-between(sm, md) {
      &:not(:last-child):not(:nth-child(2n)) {
        &:after {
          display: block;
        }
      }
    }
  }
}

#blog-horizontal {
  .blog-articulo {
    @include media-breakpoint-only(xs) {
      padding-top: 1rem;
      border-top: 1px solid $black;
    }
  }
  .blog-articulo__info {
    @include media-breakpoint-up(sm) {
      padding-top: 1rem;
      border-top: 1px solid $black;
    }
  }
}

#blog-destacados {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid $black;
  .titulo {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}
