#newsletterWidget {
  position: fixed;
  bottom: -500px;
  right: 20px;
  max-width: 320px;
  z-index: 1050;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  font-size: 13px;
  opacity: 0;
  transition: opacity 0.5s ease;
  .newsletter-header {
    background-color: #cb010f;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
  }
  .message-ok,
  .message-ok-existente,
  .message-error {
    padding: 15px;
    background: white;
  }
  .message-ok {
    height: 100px;
  }
  .message-error {
    height: 120px;
  }
  .message-ok-existente {
    height: 200px;
  }
  .newsletter-body {
    padding: 15px;
    background: white;
  }
  .newsletter-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
  }
}
#newsletterForm,
#newsletterWidgetForm {
  .is-invalid {
    border: 1px solid #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(233, 34, 48, 0.25);
  }
  .select-newsletter-widget {
    width: 100%;
  }
  .select2-container--default {
    .select2-selection--multiple {
      border-color: #000;
      border-radius: 0;
    }
  }
  input[type="radio"] {
    accent-color: #cb010f;
  }
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #d8d8d8;
  color: #000;
}
