#compras-acumuladas {
  display: flex;
  margin-bottom: 1rem;
  .compras-acumuladas__num {
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: $black;
    border: 1px solid $dark-grey;
  }
  .compras-acumuladas__num--active {
    @extend .compras-acumuladas__num;
    background-color: $dark-grey;
    border: 1px solid $dark-grey;
    color: $white;
  }
}
