#mas-vendidos {
  .libro,
  .libro-item {
    margin-top: 20px;
  }
  .libro-hover__overlay {
    padding-top: 30px;
  }
}

.carousel-section {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .titulo {
    margin-bottom: 1rem;
  }
  .slider {
    margin-top: 3rem;
    @include media-breakpoint-only(xs) {
      margin-top: 1rem;
    }
  }
  .slider-agenda {
    .slick-track {
      .slick-slide {
        @include media-breakpoint-up(md) {
          margin-left: 1rem;
          margin-right: 1rem;
        }
        margin-bottom: 1rem;
        background-color: $white;
      }
    }
  }
}
