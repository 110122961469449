#filtros-buscador-toggler {
  padding: 0.5rem 1rem;
  background-color: $white;
  i {
    margin-left: 1rem;
  }
}

#filtros-buscador-collapse {
  @include font-size(14px);
  &.collapsing {
    display: none !important;
    transition: none !important;
  }
}

#filtros-buscador {
  @include font-size(14px);
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  .form-group {
    margin-bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    &:not(:last-child) {
      border-right: 1px solid $black;
      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid $black;
      }
    }
    &:nth-child(2) {
      @include media-breakpoint-only(sm) {
        border-right: 0;
      }
    }
    @include media-breakpoint-only(xs) {
      border-right: 0 !important;
    }
  }
  .custom-checkbox {
    margin-bottom: 0.5rem;
    .custom-control-label {
      &::before,
      &::after {
        top: 0.15rem;
      }
    }
  }
  #palabra-clave {
    input[type="search"] {
      border-right: 0 !important;
    }
    .btn {
      width: 75px;
      i {
        font-size: 20px;
      }
    }
  }
}

#filtros-buscador-borrar {
  @include font-size(14px);
  border-top: 0;
}
