#articulo__hero {
  max-height: 555px;
}

#articulo__otras-resenas {
  margin-top: 1.5rem;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  @include font-size(14px);
  font-weight: 400;
  .blog-articulo__titulo,
  .blog-articulo__subtitulo {
    @include font-size(20px);
  }
  .libro {
    margin-bottom: 1rem;
  }
  .libro__portada {
    margin-bottom: 0.5rem;
    width: 70px;
    height: auto;
  }
}

#sugerencias-consulta--grid {
  margin-top: 3rem;
  .libro {
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  .libro__portada {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
  }
}

#evento-relacionado {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid $dark-grey;
  .titulo {
    text-align: center;
    margin-bottom: 3rem;
  }
  .evento {
    hr {
      margin-left: 0;
      margin-bottom: 0;
      width: 75%;
      border-color: $black;
    }
    .btn {
      display: block;
      margin-top: 1rem;
      width: 50%;
    }
  }
  .evento__caption {
    position: static;
  }
  .evento__texto {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
  }
  .col-min {
    min-height: 300px;
  }
}
