// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.separator {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid $dark-grey;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fs-14 {
  @include font-size(14px);
  line-height: 1.5;
}

.predeterminada__actual,
.predeterminada__establecer {
  @extend .fs-14;
  display: block;
}
.predeterminada__actual {
  font-style: italic;
}

.text-eco {
  color: #0b6a54;
}
