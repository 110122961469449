#resumen #resumen__sidebar #resumen__header,
#resumen #resumen__sidebar #resumen__footer,
#header #header__navbar .header__cesta .cesta__header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  @include media-breakpoint-only(xs) {
    padding: 0.5rem 1rem;
  }
  .resumen__titulo,
  .cesta__titulo {
    @include font-size(20px);
    font-weight: 400;
  }
}
#resumen #resumen__sidebar #resumen__header,
#header #header__navbar .header__cesta .cesta__header {
  .resumen__titulo,
  .cesta__titulo {
    margin-right: 0.5rem;
  }
  #resumen__contador,
  .cesta__contador {
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    background-color: $mid-grey;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-weight: 400;
  }
}

#resumen #resumen__sidebar #resumen__footer,
#header #header__navbar .header__cesta {
  .cesta__precio,
  #resumen__precio {
    @include font-size(20px);
    font-weight: 700;
  }
}

#resumen #resumen__sidebar #resumen__productos,
#header #header__navbar .header__cesta .cesta__productos,
.reserva-libro__productos {
  .cesta__empty {
    @include font-size(16px);
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 400;
  }
  .producto,
  .producto-academia-pass,
  .producto-vale-regalo {
    display: flex;
    .producto__img {
      flex: 0 0 65px;
      max-width: 65px;
      .producto__portada {
        width: 100%;
        height: auto;
      }
    }
    .producto__info {
      @extend .fs-14;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      margin-right: auto;
      padding: 1rem;
      line-height: 17px;
    }
    .producto__num {
      padding-top: 1rem;
      text-align: right;
      flex: 0 0 70px;
      .form-control {
        @extend .fs-14;
        text-align: right;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        margin-bottom: 0.5rem;
        height: 28px;
      }
    }
    .producto__del {
      padding-top: 1rem;
      .btn--del {
        padding: 0.2rem 0.5rem 0.5rem;
        i {
          color: $dark-grey;
          font-size: 20px;
        }
      }
    }
  }
}
