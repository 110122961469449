// -----------------------------------------------------------------------------
// This file contains styles that are specific to the shop cart page.
// -----------------------------------------------------------------------------

.formulario-compra {
  padding-bottom: 1.5rem;
  @include media-breakpoint-up(lg) {
    padding-top: 1.5rem;
  }
  .btn--primary {
    width: 100%;
  }
  .form-control,
  .custom-radio,
  .custom-checkbox,
  .custom-select,
  .titulo {
    margin-bottom: 1.5rem;
  }
  .btn--secondary {
    font-weight: 700;
  }
  .no-gutters {
    margin-bottom: 1rem;
    .btn {
      width: 100%;
      height: 100%;
    }
  }
  .row-header {
    @extend .fs-14;
    display: block;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 1rem;
    background-color: $light-grey;
    padding: 0.5rem 1rem;
  }
  #direcciones {
    @extend .separator;
  }
  &__bloque {
    @extend .fs-14, .separator;
    .radio-header {
      @extend .row-header;
      padding-left: 2.2rem !important;
      .custom-control-label {
        display: block;
        font-weight: 700;
        &::before,
        &::after {
          top: 0.15rem;
        }
      }
    }
    .radio-collapse {
      margin-bottom: 1rem;
      padding-left: 2.2rem !important;
      .custom-radio {
        margin-bottom: 0;
        .custom-control-label {
          font-weight: 700;
          display: block;
          @include font-size(16px);
        }
        .custom-control-input[name="entregas_recogida"] {
          & + .custom-control-label {
            @extend .fs-14;
            text-transform: uppercase;
            font-weight: 400 !important;
            &::before,
            &::after {
              top: 0.15rem;
            }
          }
        }
        &.mensajero {
          margin-bottom: 1rem;
          .mensajero__logo {
            margin-top: 1rem;
            @include media-breakpoint-up(sm) {
              text-align: center;
            }
            .mensajero__img {
              max-width: 80%;
              height: auto;
            }
          }
        }
      }
    }
    .custom-checkbox {
      .custom-control-label {
        &::before,
        &::after {
          top: 0.15rem;
        }
      }
    }
  }
  #descuentos {
    @extend .separator;
    .descuentos__header {
      @extend .row-header;
    }
    .form-control {
      height: 100%;
      margin-bottom: 0;
    }
    .descuentos__texto {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .descuentos__texto--fs14 {
      @extend .descuentos__texto, .fs-14;
    }
    #compras-acumuladas {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .btn--secondary--eliminar {
      @extend .btn--secondary;
      margin-top: 1rem;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }
  #consentimientos {
    @extend .fs-14;
    #consentimientos__box {
      padding: 1rem;
      margin-bottom: 1rem;
      @include media-breakpoint-up(sm) {
        padding: 1.5rem;
        margin-top: 1.5rem;
        margin-bottom: 3rem;
      }
      background-color: $light-grey;
      .custom-checkbox {
        .custom-control-label {
          font-weight: 400;
          &::before,
          &::after {
            top: 0.15rem;
          }
        }
        .custom-control-label__link {
          text-decoration: underline;
          color: inherit;
          @include on-event() {
            text-decoration: none;
          }
        }
      }
    }
  }
  #resumen {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
    #resumen__sidebar {
      z-index: 1020;
      top: 0;
      @include media-breakpoint-up(lg) {
        position: sticky;
      }
      @include media-breakpoint-down(md) {
        background-color: $white;
        width: 100%;
        left: 0;
      }
      #resumen__header {
        position: relative;
        border: 1px solid $dark-grey;
        border-bottom: 0;
        #toggler-collapse {
          @extend .stretched-link;
          display: block;
          margin-right: 0.5rem;
          @include media-breakpoint-up(lg) {
            display: none;
          }
          margin-top: 0.2rem;
          color: inherit;
          text-decoration: underline;
        }
        #resumen__contador {
          margin-left: auto;
        }
      }
      #resumen__scrollable {
        border: 1px solid $dark-grey;
        @include media-breakpoint-down(md) {
          overflow-y: auto;
        }
        #resumen__collapse {
          @include media-breakpoint-up(lg) {
            &.collapse:not(.show) {
              display: block;
            }
          }
          @include media-breakpoint-down(md) {
            &.collapse:not(.show) {
              display: none;
            }
          }
          #resumen__desglose {
            font-weight: 700;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
            .desglose {
              display: flex;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              .desglose__titulo {
                @extend .fs-14;
                margin-right: auto;
              }
            }
          }
        }
        #resumen__footer {
          #resumen__iva {
            @extend .fs-14;
            margin-right: auto;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}
