.dropdown {
  .dropdown-toggle {
    &::after {
      content: none;
    }
  }
  .dropdown-menu {
    border-radius: 0;
    width: 100%;
  }
  &.dropdown-primary {
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $black;
    }
    .dropdown-menu {
      &#dropdownAutores {
        top: 70px !important;
        transform: none !important;
        max-height: 350px;
        overflow-y: auto;
      }
      border: 1px solid $black;
      .dropdown-item {
        padding: 0.5rem 1rem;
        white-space: normal;
        line-height: 1.2;
        @include on-event() {
          background-color: $dark-grey;
          color: $white;
        }
      }
    }
  }
}

.dropdown-float {
  .dropdown-menu {
    margin-top: 1rem;
    padding: 0;
    border: 0;
    z-index: calc(#{$zindex-modal-backdrop} + 1);
    @include media-breakpoint-up(sm) {
      min-width: 365px;
    }
    @include media-breakpoint-only(xs) {
      min-width: 85vw;
    }
    &.dropdown-menu-right {
      margin-right: -10px;
      &::before {
        right: 10px;
      }
    }
    &.dropdown-menu-left {
      margin-left: -10px;
      &::before {
        left: 10px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: -14px;
      border-right: 10px solid transparent;
      border-bottom: 14px solid #fff;
      border-left: 10px solid transparent;
      border-bottom-color: #fff;
    }
    &.no-arrow::before {
      content: none;
    }
  }
}
