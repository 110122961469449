.slider {
  opacity: 0;
}

.slick-slider:not(.slick-vertical) {
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: inherit;
  }
}

.slick-arrow {
  width: 45px !important;
  height: 60px !important;
  z-index: 2;
  &::before {
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900;
    font-size: 28px !important;
  }
  &.slick-prev {
    left: -45px;
    &::before {
      content: "\f053";
      color: $dark-grey;
    }
  }
  &.slick-next {
    right: -45px;
    &::before {
      content: "\f054";
      color: $dark-grey;
    }
  }
  &.slick-disabled {
    display: none;
  }
}

.slick-dots {
  position: static;
  li {
    margin: 0 3px !important;
    width: 12px !important;
    height: 12px !important;
    &:only-child() {
      display: none;
    }
    &.slick-active {
      button {
        &::before {
          background-color: $dark-grey;
        }
      }
    }
    button {
      width: 12px !important;
      height: 12px !important;
      &::before {
        content: "" !important;
        opacity: 1 !important;
        width: 12px !important;
        height: 12px !important;
        border: 1px solid $dark-grey;
        background-color: transparent;
        border-radius: 100%;
      }
    }
  }
}
