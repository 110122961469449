.reserva-libro {
  .pop-header {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.reserva-libro__tiendas {
  padding: 1rem 1.5rem;
  .custom-control-label {
    @extend .fs-14;
    text-transform: uppercase;
    &::before,
    &::after {
      top: 0.025rem;
    }
  }
}
.reserva-libro__tiendas,
.reserva-libro__productos {
  border-top: 1px solid #747474;
}
.reserva-libro__completar {
  @extend .fs-14;
  .btn {
    width: 100%;
  }
}
.reserva-libro__login {
  display: none;
}
.reserva-libro__completada,
.reserva-libro__completada_widget {
  @extend .fs-14;
  display: none;
  img {
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 1rem;
    width: 65px;
    height: 65px;
    object-fit: cover;
    vertical-align: top;
  }
  .btn {
    float: left;
    width: 50%;
    @include media-breakpoint-only(xs) {
      width: 100%;
    }
  }
}
