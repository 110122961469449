#catalogo {
  .tab-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $dark-grey;
    .tab-pane {
      .libro {
        margin-top: 20px;
      }
      .libro__ranking {
        background-color: $light-grey;
      }
    }
  }
}

#catalogo_submaterias {
  ul {
    padding-left: 0;
    list-style: none;
    margin-right: 1rem;
    margin-bottom: 3rem;
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
    @include media-breakpoint-only(xs) {
      text-align: center;
    }
    line-height: 1.1;
    > li {
      margin-bottom: 0.5rem;
    }
    @include media-breakpoint-only(sm) {
      columns: 2;
    }
    @include media-breakpoint-only(md) {
      columns: 3;
    }
    @include media-breakpoint-up(lg) {
      columns: 4;
    }
  }
}

#catalogo__agenda {
  .carousel-section {
    &:not(:last-child) {
      padding-bottom: 0;
    }
    background-color: transparent !important;
  }
  .titulo {
    margin-bottom: 1rem;
  }
  .btn-agenda {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .btn {
      width: 100%;
    }
  }
  .evento {
    background-color: transparent;
  }
  .slick-slide {
    background-color: #eaeaea !important;
  }
}

#instafeedINF.instafeed {
  display: flex;
  flex-wrap: wrap;
  .instafeed-item {
    flex: 0 0 32%;
    margin: 2px;
    overflow: hidden;
    position: relative;
    height: 0;
    padding-bottom: 33.33%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
