.login-signup {
  .dropdown-toggle {
    padding: 0;
    font-weight: 400;
    i {
      margin-left: 8px;
      font-size: 22px;
    }
  }
  .dropdown-menu {
    &::before {
      @include media-breakpoint-only(xs) {
        content: none;
      }
    }
  }
  .login-form {
    .form-group {
      > a {
        @include font-size(14px);
        font-weight: 400;
        text-decoration: underline;
        @include on-event() {
          text-decoration: none;
        }
      }
    }
    .custom-checkbox {
      margin-top: 0.5rem;
      .custom-control-label {
        @include font-size(14px);
        color: $dark-grey;
        &::before,
        &::after {
          top: 0.15rem;
        }
      }
    }
  }
}
