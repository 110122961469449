#libro {
  margin-top: 1rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 1rem;
  }
}

#libro__portada {
  width: 157px;
  height: auto;
  max-width: 157px;

  @include media-breakpoint-up(md) {
    width: 222px;
    height: auto;
    max-width: 222px;
  }

  @include media-breakpoint-up(xl) {
    width: 280px;
    height: auto;
    max-width: 280px;
  }
}
#libro__autores {
  .libro__autor {
    font-size: 1.2rem;
    color: #4e4a4d;
  }
  .autor {
    @include font-size(20px);
    display: block;
    line-height: 1;
  }
}
#libro__titulo {
  @extend .titulo;
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
#libro__info {
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
  @include media-breakpoint-down(md) {
    margin-top: 0.5rem;
  }
  line-height: 1.4;
  text-align: left;
  > p {
    margin-bottom: 0.25rem;
    font-size: 1.1rem;
    color: #4e4a4d;
    font-weight: 200;
  }
  a {
    color: #4e4a4d;
  }
  .libro__info-label {
    margin-right: 0.25rem;
    font-weight: 700;
  }
}
#libro__sellos {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
  img {
    width: 60px;
  }
  .sello {
    margin-right: 1rem;
    margin-top: 1rem;
  }
}
#libro__sellos__2 {
  img {
    width: 60px;
    height: auto;
  }
  .sello {
    margin-left: -65px;
  }
}

#libro__compra {
  margin-bottom: 3rem;
  .badge {
    display: inline-block;
    margin-right: 0.5rem;
  }
  .dropdown-menu {
    &::before {
      content: none;
    }
    .dropdown-menu-header {
      padding-top: 1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  .dropdown-toggle,
  > .btn {
    width: 100%;
    margin-bottom: 1rem;
  }
  .libro__disponible {
    margin-bottom: 1rem;
  }

  .libro__list {
    .libro__list__header {
      [data-toggle="collapse"] {
        .fas::after {
          content: "\f077";
          font-size: 12px;
          color: $brand-color;
        }

        &.collapsed .fas::after {
          content: "\f078";
          font-size: 12px;
          color: $brand-color;
        }
      }
    }

    .libro__list__item {
      list-style-type: none;
      color: #626262;
      font-weight: 700;
      span {
        color: $brand-color;
      }
    }
  }
}

#libro__gastos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}
#gastos__precio {
  @extend .titulo;
  font-size: 2rem;
}
#gastos__envio {
  color: #615656;
  font-weight: 700;
  font-size: 0.75rem;
  @include media-breakpoint-down(md) {
    font-size: 0.625rem;
  }
}

#libro__aviso {
  line-height: 1.2;
  .dropdown-menu {
    @extend .fs-14;
    .btn {
      float: left;
      width: 50%;
      @include media-breakpoint-only(xs) {
        width: 100%;
      }
    }
  }
}

#libro__sinopsis {
  margin-bottom: 3rem;
  .btn {
    width: 100%;
    border-color: $black;
  }
}

#sinopsis__text {
  margin-bottom: 1rem;
}
#sinopsis__text-toggle {
  display: none;
}

#libro__otros-lectores {
  .libro {
    text-align: left;
    margin-bottom: 1rem;
    vertical-align: top;
    line-height: 1.2;
  }
  .libro__portada {
    float: left;
    margin-right: 1rem;
    width: 70px;
  }
  .libro__autor,
  .libro__titulo {
    display: block;
    min-height: 44px;
  }
  .libro__titulo {
    font-weight: 700;
  }
}

#libro__materias-autores {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .caja {
    text-align: center;
    padding: 1rem;
    width: 160px;
    height: 160px;
    background-color: $light-grey;
    .badge {
      word-wrap: break-word;
      white-space: normal;
      line-height: 1.2;
    }
  }
  ul {
    padding-left: 0;
    list-style: none;
    margin-top: 0.5rem;
    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
      columns: 2;
      li {
        display: inline-block;
      }
    }
  }
}
