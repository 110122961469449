#blog {
  #filtros-buscador-toggler {
    margin-bottom: 1rem;
  }
  #filtros-buscador {
    margin-top: -1px;
  }
  #filtros-buscador {
    .list-unstyled {
      columns: 3;
      column-gap: 15px;
      li {
        display: inline-block;
        width: 100%;
      }
      @include media-breakpoint-down(sm) {
        columns: 2;
      }
    }
  }
}
