.a2a_kit {
  margin-bottom: 1rem;
  .a2a_svg {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    &.a2a_s_facebook {
      border-radius: 50%;
      background-color: #979797 !important;
      svg {
        path {
          fill: #fff;
        }
      }
    }

    background-color: transparent !important;
    svg {
      path {
        fill: #979797;
      }
    }
  }
}
