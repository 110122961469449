/**
 * Basic typography style for copy text
 */
body {
  @include font-size(16px);
  font-family: $text-font-stack;
  color: $text-color;
}

.text-brand {
  color: $brand-color;
}

.font-family-crimson {
  font-family: "Crimson Pro", serif;
}
