#banners {
  margin-top: 1.5rem;
  .banner {
    width: 100%;
    margin-top: 1.5rem;
    background-position: center;
    background-size: cover;
    padding-top: 50%;
  }
}

#banners-top {
  @media (max-width: 768px) {
    .container {
      max-width: 750px;
    }
  }
  @media (max-width: 576px) {
    .container {
      max-width: 560px;
    }
  }
}
