// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

#footer-cesta {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $light-grey;
}

#footer {
  background-color: $light-grey;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .footer__logo {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &_menu {
    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-top: 0;
    }
    @include media-breakpoint-down(lg) {
      padding-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
    @include media-breakpoint-up(lg) {
      border-left: 1px solid $dark-grey;
    }
    @include media-breakpoint-down(md) {
      border-top: 1px solid $dark-grey;
    }
    .nav {
      @include media-breakpoint-up(lg) {
        margin-left: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }
      @include media-breakpoint-only(md) {
        justify-content: center;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      .nav-item {
        @include media-breakpoint-down(sm) {
          text-align: center;
        }
        .nav-link {
          display: inline-block;
          padding: 0;
          @include media-breakpoint-only(md) {
            padding: 5px 10px;
          }
          &.active {
            font-weight: bold;
          }
          @include on-event() {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__bar {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
  }
  &__redes {
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-right: 1px solid $dark-grey;
  }
  &__credits {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
