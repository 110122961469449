#mi-cuenta {
  @include media-breakpoint-up(lg) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .btn--primary {
    width: 100%;
  }
  .dropdown {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::after {
        content: "";
        border-top: 0.8em solid;
        border-top-color: $dark-grey;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
      }
    }
    .dropdown-menu {
      min-width: 0;
    }
  }
  #mi-cuenta__nav {
    @extend .nav;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      display: flex;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    > li {
      @extend .nav-item;
      > a {
        @extend .nav-link;
      }
    }
  }
  #mi-cuenta__dropdown {
    width: 100%;
    @include media-breakpoint-up(md) {
      display: none;
    }
    @include media-breakpoint-down(md) {
      display: block;
    }
    .dropdown-toggle {
      text-align: left;
      width: 100%;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 0;
      width: 100%;
      a {
        @extend .dropdown-item;
      }
    }
  }
  #mi-cuenta__dropdown,
  #mi-cuenta__nav {
    margin-bottom: 1.5rem;
  }
  #mi-cuenta__nav a,
  #mi-cuenta__dropdown a,
  #mi-cuenta__dropdown .dropdown-toggle {
    color: $black !important;
    border-left: 8px solid $light-grey !important;
    background-color: $light-grey !important;
    padding: 0.5rem 1rem !important;
  }
  #mi-cuenta__dropdown .dropdown-toggle,
  #mi-cuenta__nav a.active,
  #mi-cuenta__dropdown a.active {
    font-weight: 700;
    border-left: 8px solid $dark-grey !important;
  }
  #mi-cuenta__nav a,
  #mi-cuenta__dropdown a {
    @include on-event() {
      font-weight: 700;
      text-decoration: none;
    }
  }
  #mi-cuenta__datos {
    a {
      text-decoration: underline;
      @include on-event() {
        text-decoration: none;
      }
    }
    .datos__titulo {
      @include font-size(20px);
      margin-bottom: 1.5rem;
      font-weight: 400;
    }
    #filtro-pedidos {
      width: calc(100% + 1rem + 5px);
      margin-left: calc(-1rem - 5px);
      .dropdown-toggle {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid $dark-grey;
      }
      .dropdown-menu {
        margin-top: 0.5rem;
        border: 1px solid $dark-grey;
        .dropdown-item {
          padding-left: 1rem;
          padding-right: 1rem;
          text-decoration: none;
          margin-top: 0;
          margin-bottom: 0;
          color: $black;
          @include on-event() {
            font-weight: bold;
            background-color: transparent;
            color: inherit;
          }
        }
      }
    }

    #filtro-reservas {
      width: calc(100% + 1rem + 5px);
      margin-left: calc(-1rem - 5px);
      .dropdown-toggle {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid $dark-grey;
      }
      .dropdown-menu {
        margin-top: 0.5rem;
        border: 1px solid $dark-grey;
        .dropdown-item {
          padding-left: 1rem;
          padding-right: 1rem;
          margin-top: 0;
          margin-bottom: 0;
          text-decoration: none;
          color: $black;
          @include on-event() {
            font-weight: bold;
            background-color: transparent;
            color: inherit;
          }
        }
      }
    }
    #datos-actualizados {
      display: inline-block;
      padding: 0.5rem 1rem;
      margin-bottom: 1.5rem;
      background-color: $light-grey;
      font-weight: 400;
    }
    #contrasena-actual {
      @extend .no-gutters;
      margin-bottom: 1rem;
      .form-group {
        margin-bottom: 0;
      }
      #contrasena-actual__mostrar {
        align-self: flex-end;
        #mostrar__boton {
          @extend .btn, .btn--secondary, .form-control;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
        }
      }
    }
    .reserva,
    .pedido {
      margin-bottom: 1.5rem;
      border: 1px solid #747474;
      border-top: 0;
      .no-gutters {
        border-top: 1px solid $dark-grey;
        &:first-child {
          border-bottom: 1px solid $dark-grey;
        }
        .btn {
          height: 100%;
          i {
            margin-right: 0.5rem;
            color: $dark-grey;
          }
        }
      }
      &.reserva--no-recogida {
        .producto__libro,
        .producto__precio {
          background-color: $light-grey;
        }
        .no-gutters {
          &:first-child {
            border-bottom: 0;
          }
        }
      }
      .reserva__producto,
      .pedido__producto {
        border-top: 0;
      }
      .producto__precio,
      .producto__libro,
      .reserva__fecha,
      .pedido__fecha,
      .reserva__lugar,
      .reserva__valida,
      .reserva--no-recogida__alerta,
      .pedido__contador,
      .pedido__estado,
      .pedido__entregado,
      .pedido__ver-todo {
        padding: 0.5rem 1rem;
      }
      .reserva__fecha,
      .pedido__fecha,
      .pedido__contador,
      .pedido__estado,
      .reserva__lugar {
        font-weight: 400;
      }
      .reserva__lugar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-transform: uppercase;
        i {
          margin-right: 0.5rem;
          color: $dark-grey;
        }
      }
      .producto__portada {
        img {
          width: 40px;
        }
      }
      .producto__precio,
      .producto__libro,
      .producto__precio-total,
      .pedido__referencia {
        padding: 0.5rem 1rem;
      }
      .pedido__referencia {
        @include media-breakpoint-only(xs) {
          border-bottom: 1px solid $dark-grey;
        }
        @include media-breakpoint-up(sm) {
          border-right: 1px solid $dark-grey;
        }
      }
      .producto__libro {
        @extend .fs-14;
        line-height: 1.2;
      }
      .producto__precio {
        text-align: right;
        font-weight: 700;
      }
      .producto__precio-total {
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-up(sm) {
          border-left: 1px solid $dark-grey;
        }
        span:last-child {
          margin-left: 5px;
        }
      }
      .pedido__contador {
        @include media-breakpoint-up(sm) {
          text-align: left;
        }
        @include media-breakpoint-only(xs) {
          text-align: right;
        }
        .badge {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          background-color: $light-grey;
        }
      }
      .pedido__estado {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        background-color: $light-grey;
        @include media-breakpoint-only(xs) {
          border-top: 1px solid $dark-grey;
        }
        span[data-toggle="tooltip"] {
          margin-left: auto;
          width: 20px;
        }
      }
      .pedido__entregado {
        text-align: center;
        font-weight: 700;
        border-top: 1px solid $dark-grey;
      }
      .reserva__valida,
      .pedido__ver-todo {
        background-color: $light-grey;
      }
      .pedido__ver-todo {
        text-align: center;
        border-top: 1px solid $dark-grey;
      }
      .reserva__cancelar {
        padding: 1rem;
        margin-bottom: 0;
        label {
          padding: 0.5rem 1rem;
          width: 100%;
          background-color: $light-grey;
        }
        textarea {
          height: 80px;
        }
      }
      .reserva--no-recogida__alerta {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $dark-grey;
        i {
          margin-right: 0.5rem;
          font-size: 20px;
        }
      }
      .btn {
        width: 100%;
        img {
          margin-right: 1rem;
        }
      }
    }
    .curso {
      margin-bottom: 1.5rem;
      border: 1px solid #747474;
      border-top: 0;
      .no-gutters {
        border-top: 1px solid $dark-grey;
        &:first-child {
          border-bottom: 1px solid $dark-grey;
        }
        .btn {
          height: 100%;
          i {
            margin-right: 0.5rem;
            color: $dark-grey;
          }
        }
      }
      .curso__producto {
        border-top: 0;
      }
      .producto__precio,
      .producto__libro,
      .curso__fecha,
      .curso__lugar {
        padding: 0.5rem 1rem;
      }
      .curso__fecha,
      .curso__lugar {
        font-weight: 400;
      }
      .curso__lugar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-transform: uppercase;
        i {
          margin-right: 0.5rem;
          color: $dark-grey;
        }
      }
      .producto__portada {
        img {
          height: 50px;
          @include media-breakpoint-down(sm) {
            height: 70px;
          }
        }
      }
      .producto__precio,
      .producto__libro,
      .producto__precio-total,
      .curso__referencia {
        padding: 0.5rem 1rem;
      }
      .curso__referencia {
        @include media-breakpoint-only(xs) {
          border-bottom: 1px solid $dark-grey;
        }
        @include media-breakpoint-up(sm) {
          border-right: 1px solid $dark-grey;
        }
      }
      .producto__libro {
        @extend .fs-14;
        line-height: 1.2;
      }
      .producto__precio {
        text-align: right;
        font-weight: 700;
      }
      .producto__precio-total {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        @include media-breakpoint-up(sm) {
          border-left: 1px solid $dark-grey;
        }
        span:last-child {
          margin-left: 5px;
        }
      }
      .curso__lugar {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        background-color: $light-grey;
        @include media-breakpoint-only(xs) {
          border-top: 1px solid $dark-grey;
        }
        span[data-toggle="tooltip"] {
          margin-left: auto;
          width: 20px;
        }
      }
      .btn {
        width: 100%;
        img {
          margin-right: 1rem;
        }
      }
    }
    .tarjeta {
      margin-bottom: 1rem;
      .tarjeta__datos {
        display: flex;
        padding: 0.3rem 1rem;
        flex-direction: column;
        justify-content: space-between;
        text-transform: uppercase;
        border: 1px solid $dark-grey;
        .tarjeta__tipo {
          font-weight: 400;
        }
        .tarjeta__nombre {
          font-weight: 700;
        }
      }
      .tarjeta__botones {
        border: 1px solid $dark-grey;
        @include media-breakpoint-up(sm) {
          border-left: 0;
        }
        @include media-breakpoint-only(xs) {
          border-top: 0;
        }
        .btn {
          @extend .btn--icon;
          width: 100%;
          height: 100%;
          img {
            margin-right: 1rem;
          }
        }
      }
      .tarjeta__nombrar {
        padding: 1rem;
        border: 1px solid $dark-grey;
        border-top: 0;
        .form-group {
          label {
            padding: 0.5rem 1rem;
            width: 100%;
            margin-bottom: 1rem;
            background-color: $light-grey;
          }
        }
        .btn {
          width: 100%;
          height: 100%;
        }
      }
    }
    #boletines {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
