// -----------------------------------------------------------------------------
// This file contains all styles related to the modal component.
// -----------------------------------------------------------------------------

.modal {
  .modal-content {
    border-radius: 0;
    .modal-body {
      ul,
      ol {
        padding-left: 1.5rem;
        li {
          margin-bottom: 0.5rem;
        }
      }
      a {
        color: inherit;
        text-decoration: underline;
        @include on-event() {
          text-decoration: none;
        }
      }
    }
  }
  &#finalizarCompra {
    .modal-header {
      border: 0;
    }
    .modal-content {
      .modal-body {
        padding-top: 0;
        padding-bottom: 0;
        @include font-size(16px);
      }
    }
    .modal-footer {
      padding: 0;
      .btn {
        margin: 0;
      }
    }
  }
}

.cookieConsent {
  font-weight: 400;
  background-color: #e92230;
  color: #fff;
  text-align: center !important;
  padding: 1rem !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 600px;
  max-width: 100%;
  .accept-policy {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border: 2px solid #fff !important;
    letter-spacing: 0.66px;
    background-color: #e92230;
    color: #fff;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}
