// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($brand-color, 0.25);
  }
  &.btn--primary {
    font-weight: 700;
    border-color: #cb010f !important;
    background-color: #cb010f !important;
    color: $white !important;
    @include on-event() {
      background-color: $brand-color !important;
      border-color: $brand-color !important;
    }
  }
  &.btn--secondary {
    font-weight: 700;
    text-transform: uppercase;
    border-color: $dark-grey !important;
    background-color: $dark-grey !important;
    color: $white !important;
  }
  &.btn--cancelar {
    font-weight: 700;
    font-weight: 700;
    border-color: $light-grey !important;
    background-color: $light-grey !important;
    color: $black !important;
  }
  &.btn--link {
    padding: 0;
    text-align: left;
    color: $black;
    text-decoration: underline;
    @include on-event() {
      text-decoration: none;
    }
  }
  &.btn--collapse {
    &[aria-expanded="true"] {
      background-color: $dark-grey !important;
      color: $white;
    }
  }
  &.btn-outline--black {
    background-color: transparent;
    border-color: $black;
  }
  &.btn-outline--brand {
    background-color: transparent;
    border-color: #cb010f;
    color: #cb010f;
    font-weight: 700;
    @include on-event() {
      border: 1px solid $brand-color;
      color: $brand-color;
    }
  }
  &.btn-outline--dark-grey {
    background-color: transparent;
    border-color: $dark-grey;
  }
  &.btn--disabled {
    background-color: transparent !important;
    color: $black !important;
    border-color: $black !important;
    opacity: 0.3;
    pointer-events: none;
  }
  &.btn--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    img {
      width: 15px;
    }
  }
  &.btn--edit {
    padding: 0;
    img {
      width: 15px;
    }
  }
  &.btn--del {
    padding: 0;
    img {
      width: 15px;
    }
  }
}
