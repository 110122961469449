#buscador {
  div[data-target="#buscador-avanzado"] {
    &[aria-expanded="true"] {
      display: none;
    }
    &[aria-expanded="false"] {
      display: block;
    }
  }
}

#buscador-avanzado {
  &.collapsing {
    transition: none;
    display: none;
  }
  .titulo {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  > form {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .form-group {
      margin-bottom: 1.5rem;
    }
    .btn {
      width: 100%;
    }
  }
}

#buscador-avanzado__filtros {
  @extend .fs-14;
  text-align: center;
  display: none;
  .btn--link {
    @extend .fs-14;
    display: inline-block;
  }
}

#buscador__listado {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .resultado {
    margin-bottom: 0.5rem;
    line-height: 1.2;
    background-color: $light-grey;
    .libro__compra {
      margin-left: 0.5rem;
      z-index: auto;
    }
  }
  .resultado__img {
    padding: 0;
    > img {
      width: 65px;
    }
  }
}

#buscador__filtros {
  @include media-breakpoint-up(lg) {
    &.collapse:not(.show) {
      display: block;
    }
  }
  ul {
    margin-bottom: 1rem;
    padding-left: 0;
    list-style: none;
    @include media-breakpoint-up(sm) {
      padding-left: 1rem;
    }
    @include media-breakpoint-only(xs) {
      padding-left: 0;
    }
    li {
      padding-top: 2px;
      padding-bottom: 2px;
      a {
        @extend .fs-14;
        display: inline-block;
        line-height: 1.2;
      }
    }
  }
  .dropdown {
    margin-bottom: 1.5rem;
    > label {
      text-transform: uppercase;
    }
  }
  .btn--link {
    @extend .fs-14;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
}
