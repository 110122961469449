.tooltip {
  &.show {
    opacity: 1;
  }
  .arrow {
    &::before {
      border-bottom-color: $dark-grey;
    }
  }
  .tooltip-inner {
    border-radius: 0;
    text-align: left;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border: 1px solid $dark-grey;
    background-color: $white;
    color: $black;
    line-height: 1.4;
  }
}

[data-toggle="tooltip"] {
  cursor: pointer;
}
