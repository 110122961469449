#evento__tipo {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
#evento__libros {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $light-grey;
}
#evento__imagen {
  display: flex;
  overflow: hidden;
}
#evento__caption {
  @include font-size(20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: rgba($black, 0.6);
  color: $white;
}
#evento__dia,
#evento__hora,
#evento__ubicacion {
  padding: 0.25rem 1rem;
}
#evento__hora,
#evento__ubicacion {
  display: inline-block;
}
#evento__titulo {
  @include font-size(32px);
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-weight: 400;
}
#evento__subtitulo {
  @include font-size(20px);
  margin-bottom: 1.5rem;
  padding-block-end: 1rem;
  line-height: 1.2;
  font-weight: 400;
  border-left: 1px solid $dark-grey;
  padding-left: 1rem;
}
#evento__editorial {
  @include font-size(16px);
  font-weight: 400;
}
#evento__reservar {
  width: 100%;
  margin-bottom: 1.5rem;
}
#eventos-relacionados {
  border-top: 1px solid $dark-grey;
  .btn {
    margin-top: 3rem;
  }
}

#articulos-relacionados {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid $dark-grey;
  .titulo {
    text-align: center;
    margin-bottom: 3rem;
  }
  .blog-articulo--destacado {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
  }
  .libro-hover {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 180px;
  }
  .col-min {
    min-height: 300px;
  }
  #blog-vertical {
    .blog-articulo {
      @include media-breakpoint-up(sm) {
        &::before {
          content: none;
        }
      }
    }
    .blog-articulo__portada-wrapper {
      .blog-articulo__portada {
        width: 230px;
        max-width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
