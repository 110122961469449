.evento {
  position: relative;
  text-align: left;
  background-color: $light-grey;
}
.evento__imagen {
  position: relative;
  width: 100%;
  padding-top: 66.66%;
  overflow: hidden;
}
.evento__libros {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.evento__libros--bg {
  img,
  picture {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.evento__libros--1 {
  overflow: hidden;
  img {
    height: 100%;
    width: auto;
  }
}
.evento__libros--2 {
  img {
    max-height: 100%;
    width: 45%;
    object-fit: contain;
  }
}

.evento__caption {
  position: absolute;
  width: 100%;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($black, 0.6);
  color: $white;
}
.evento__fecha,
.evento__ubicacion {
  padding-left: 1rem;
  padding-right: 1rem;
}
.evento__descripcion {
  padding: 1rem;
  @include font-size(18px);
  line-height: 1.4;
  &.is-academia {
    @include font-size(16px);
    .evento__titulo {
      @include font-size(22px);
      line-height: 1.1;
      font-style: normal;
      font-family: "Crimson Pro", serif;
    }
  }
  hr {
    border-color: $dark-grey;
  }
}
.evento__titulo {
  font-style: italic;
  line-height: 1.2;
}
.evento__autor {
  font-weight: 400;
}
.evento__enlace {
  @extend .stretched-link;
}
