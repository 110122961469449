#registro {
  @include media-breakpoint-up(lg) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .btn--link {
    @include font-size(14px);
  }
  .btn--primary {
    width: 100%;
  }
}
#registro__titulo {
  @extend .titulo;
  text-align: center;
  margin-bottom: 1.5rem;
}
#registro__recaptcha {
  margin-top: 3rem;
}
#registro__aceptacion {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
