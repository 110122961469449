// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

input[type="text"].form-control,
input[type="number"].form-control,
input[type="email"].form-control,
input[type="password"].form-control,
input[type="search"].form-control,
textarea.form-control,
.custom-select {
  border-radius: 0;
  border: 1px solid $black;
  color: $black;
  &::placeholder {
    font-weight: 400;
    opacity: 0.5;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($brand-color, 0.25);
  }
}
.custom-select {
  &:invalid {
    font-weight: 400;
    color: rgba(108, 117, 125, 0.5);
  }
  option {
    color: $black;
  }
}
.custom-control-input,
.custom-control-label {
  cursor: pointer;
}
.custom-control-input {
  &:focus ~ .custom-control-label {
    &::before {
      box-shadow: 0 0 0 0.2rem rgba($brand-color, 0.25);
    }
  }
}
.custom-radio {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e") !important;
      }
    }
  }
  .custom-control-label {
    &::before {
      background-color: transparent !important;
      border-color: $black !important;
    }
  }
}
.custom-checkbox {
  .custom-control-label {
    &::before {
      border-radius: 0;
      background-color: transparent;
      border-color: $black !important;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        color: $white !important;
        border-color: $dark-grey !important;
        background-color: $dark-grey !important;
      }
    }
    &:active ~ .custom-control-label {
      &::before {
        background-color: $brand-color !important;
        opacity: 0.5;
      }
    }
  }
}

.form-group {
  > label {
    @extend .fs-14;
    font-weight: 700;
  }
}
