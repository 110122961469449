.libro-item {
  padding: 0 0.875rem;
  margin: 1.25rem 0 2rem;
  font-weight: 400;
  text-align: left;
  vertical-align: top;
  font-size: 1rem;
  letter-spacing: 0;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }

  .libro-item__image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 150%;
    margin-bottom: 0.75rem;

    .libro-item__ranking {
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      width: 40px;
      height: 40px;
      top: -20px;
      background-color: $white;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    .libro__portada {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .libro-item__buttons-wrapper {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      padding-top: 0.5rem;

      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      background-color: $light-grey;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .libro-item__btn {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 700;
    }
  }

  .libro-item__text-wrapper {
    line-height: 1.2rem;
    height: 105px;

    .libro-item__autores {
      margin-bottom: 0.25rem;
      -webkit-line-clamp: 2;
    }

    .libro-item__titulo {
      font-weight: 700;
      -webkit-line-clamp: 3;
    }

    .libro-item__autores,
    .libro-item__titulo {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .libro-item__price-wrapper {
    .libro-item__precio {
      font-size: 1.25rem;
      font-weight: 700;

      @include media-breakpoint-down(sm) {
        font-size: 1.125rem;
      }
    }

    img {
      width: 15px;
      height: 15px;

      @include media-breakpoint-down(sm) {
        width: 12px;
        height: 12px;
      }
    }
  }

  .bg-white {
    background-color: white;
  }
  .bg-grey {
    background-color: $light-grey;
  }

  @media (hover: hover) {
    &:hover {
      .libro-item__buttons-wrapper {
        opacity: 1;
      }
    }
  }
}
