.breadcrumb {
  font-weight: 700;
  // text-transform: uppercase;
  color: #626262;

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.5rem;
  }

  @include media-breakpoint-only(xs) {
    font-size: 0.875rem;
  }
  @include media-breakpoint-only(xs) {
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.25rem;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      padding-right: 0.25rem;
    }
  }

  a {
    color: inherit;
  }
}
