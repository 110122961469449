#stickerSlider {
  transform: rotate(180deg);
  height: $sticker-height;

  .slick-slide {
    border: 0;
    transform: rotate(-180deg);
  }

  .sticker {
    background-color: #d8d8d8;
    height: $sticker-height;
    font-size: 12px;
    white-space: nowrap;

    strong {
      font-family: "Crimson Pro", serif;
      font-size: 14px;
    }

    @include media-breakpoint-up(sm) {
      gap: 1.5rem;
    }
    @include media-breakpoint-only(xs) {
      gap: 0.5rem;
    }
  }
}
