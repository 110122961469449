.nav-tabs {
  border: 0;
  justify-content: center;
  .nav-link {
    border: 0;
    border-radius: 0;
    text-decoration: none;
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &.active {
      background-color: $dark-grey;
      color: $white;
    }
    @include on-event() {
      background-color: $dark-grey;
      color: $white;
    }
  }
}
